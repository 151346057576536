ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul {
    position: relative;
    width: 100%;
    padding: 16px;
}

li {
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    width: 100%;
    margin-bottom:16px;
    position: relative;
}

.row {
    width: 100%;
    height: 8px;
    background-color: #999;
    border-radius: 10px;
    margin-top: 12px;
}
