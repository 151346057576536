@media screen and (max-width: 599px) {
    .node-box {
        width: 80vw;
    }
}

/* Small devices (landscape phones, 600px and up) */
@media screen and (min-width: 600px) and (max-width: 959px) {
    .node-box {
        width: 70vw;
    }
}

/* Medium devices (tablets, 960px and up) */
@media screen and (min-width: 960px) and (max-width: 1279px) {
    .node-box {
        width: 60vw;
    }
}

/* Large devices (desktops, 1280px and up) */
@media screen and (min-width: 1280px) and (max-width: 1919px) {
    .node-box {
        width: 50vw;
    }
}

/* Extra large devices (large desktops, 1920px and up) */
@media screen and (min-width: 1920px) {
    .node-box {
        width:40vw;
    }
}
