.text-updater-node {
    height: 100%;
    border: 1px solid #eee;
    /* padding: 5px; */
    border-radius: 5px;
    background: white;
}
.report-box-node{
  height: 100%;
  position: relative;
}

.text-updater-node label {
    display: block;
    color: #777;
    font-size: 12px;
}
